import * as React from "react";
import Typography from "../components/Typography";
import { Box } from "@mui/material";
import ProductHeroLayoutComingSoon from "./ProductHeroLayoutComingSoon";
import Button from "../components/Button";

const backgroundImage =
  "/smoke_mountains_background.png";

export default function ProductHeroComingSoon() {

  return (
    <ProductHeroLayoutComingSoon
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "center",
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" sx={{
                display: "flex",
                justifyContent: "center",
                mb: "40px"}}>
        New Release
      </Typography>
      <Box
          component="img"
          src="/Involuntarily_Immortal_cover_reworked_ebook-1.jpeg"
          height="auto"
          width="20%"
          minWidth="200px"
          maxWidth="400px"
          display="flex"
          sx={{ 
            justifyContent: "center",
          }}
        />
        <Typography color="inherit" variant="body1" align="center" sx={{mt:"10px"}}>
            Sable Montgrief wishes her curse would let her die. Unfortunately for her, fate has other plans.
          </Typography>
          <Typography color="inherit" variant="body2" align="center" sx={{mt:"10px"}}>
          Living alone in a cabin for decades, Sable has done her best to break the spell cast on her that
            has extended her life for centuries. She never wanted immortality; in fact, she’s spent the
            majority of her long life trying to end it. Her latest attempt has her so close to breaking free she
            can taste it...until someone breaks down her door to find her.
          </Typography>
          <Typography color="inherit" variant="body2" align="center" sx={{mt:"10px"}}>
           Adem Ozturk is looking for someone to help his daughter, Ailith, who is plagued with visions of the future she can 
           neither manage nor interpret. They’re on the run from an unknown organization with unlimited reach and are up against a 
           wall–until Adem’s wife sends a message to Ailith from beyond the grave, sending them to find Sable and recruit her to 
           their cause. If they can convince her to help they may be able to not only save Ailith, but prevent a global cataclysm. 
           The cost? Another lifetime of torturous existence for Sable.
          </Typography>
          <Typography color="inherit" variant="body1" align="center" sx={{mt:"10px"}}>
          If they fail, she'll be trapped in eternal torment; if they succeed, she'll still lose everything she loves.
          </Typography>
        <Button
          color="primary"
          size="large"
          variant="contained"
          href="https://a.co/d/fVsh11O"
          align="center"
          sx={{ 
            mt: 2,
          }}
        >
          Order on Amazon
          <br/> or Read on Kindle Unlimited
        
        </Button>
    </ProductHeroLayoutComingSoon>
  );
}
