import * as React from "react";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import FacebookIcon from '@mui/icons-material/Facebook';
import { Instagram } from '@mui/icons-material';

function Copyright() {
  return (
    <React.Fragment>
      {"© "}
      <Link color="inherit" href="https://emilybarlowwritesthings.com/">
        emilybarlowwritesthings.com
      </Link>{" "}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: "flex", bgcolor: "secondary.light" }}
    >
      <Container sx={{ my: 8, display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
                <Link
                  align="center"
                  display="block"
                  variant="body1"
                  href="https://www.facebook.com/profile.php?id=100089226551514"
                  key="Facebook"
                >
                  <FacebookIcon />
                  <span>facebook</span>
                </Link>
                <Link
                  align="center"
                  display="block"
                  variant="body1"
                  href="https://www.instagram.com/emilybarlowwritesthings/"
                  key="Instagram"
                >
                  <Instagram />
                  <span>instagram</span>
                </Link>
            </Grid>
            <Grid item xs={6}>
                <Copyright />
            </Grid>
          </Grid>
      </Container>
    </Typography>
  );
}
