import * as React from "react";
import AppFooter from "./modules/views/AppFooter";
import ProductHero from "./modules/views/ProductHero";
import ProductHeroComingSoon from "./modules/views/ProductHeroComingSoon";
import AppAppBar from "./modules/views/AppAppBar";
import withRoot from "./modules/withRoot";
import ProductHeroBooks from "./modules/views/ProductHeroBooks";
import ProductHeroAbout from "./modules/views/ProductHeroAbout";

function Index() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHero />
      <ProductHeroComingSoon />
      <ProductHeroBooks />
      <ProductHeroAbout />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);
