import * as React from "react";
import Typography from "../components/Typography";
import { Box, Grid } from "@mui/material";
import ProductHeroLayoutBooks from "./ProductHeroLayoutBooks";
import Button from "../components/Button";

const backgroundImage =
  "/books_section_background.png";

export default function ProductHeroBooks() {

  return (
    <ProductHeroLayoutBooks
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "center",
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" id="books" sx={{
                display: "flex",
                justifyContent: "center",
                mb: "40px"}}>
        Books
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={ 12 } md={ 6 } lg={ 6 } xl={ 6 }>
            <Box
                component="img"
                src="/sunchaser_ebook_cover_cropped.jpg"
                height="auto"
                width="20%"
                minWidth="200px"
                maxWidth="400px"
                display="flex"
                sx={{ 
                  border: 2,
                  borderColor: "white",
                }}
                />
                <Typography color="inherit" variant="body1" align="justify">
                  Glorya is a sunchaser, someone born with the ability to bend the weather to their will. 
                  She has spent the last five years of her life training, honing her body, her ability, 
                  and her mind in an attempt to become the most effective weatherworker the world has ever seen--but 
                  will it be enough? Or will the raw power of nature defeat her before she has a chance to prove herself?
              </Typography>
              <Typography color="inherit" variant="body2" align="justify">
                  Sunchaser follows Glorya as she struggles against the vagaries of both nature and humanity in her quest 
                  for independence. Traveling from port to port, she pays for her passage by ensuring smooth sailing on the 
                  journey, but her skill is tested time and again as she faces ever-greater natural threats. Her insular 
                  upbringing on a small, inland farm has left her with little exposure to the world at large--a world full 
                  of new experiences that she now must navigate entirely on her own.
              </Typography>
                <Button
                color="primary"
                size="large"
                variant="contained"
                href="https://a.co/d/ckFa4F4"
                align="center"
                sx={{ 
                    mt: 2,
                }}
                >
                Order on Amazon <br/>
                or Read on Kindle Unlimited
                </Button>
            </Grid>
            <Grid item xs={12} sm={ 12 } md={ 6 } lg={ 6 } xl={ 6 }>
            <Box
                component="img"
                src="/the_ravens_children_cover_rework-2.jpeg"
                height="auto"
                width="20%"
                minWidth="200px"
                maxWidth="400px"
                display="flex"
                sx={{ 
                  border: 2,
                  borderColor: "white",
                }}
                />
                <Typography color="inherit" variant="body1" align="justify">
                  An ailing king. Three possible successors to the throne. 
                  A small kingdom without defenses. Will it survive? Or will internal turmoil be its undoing?
                </Typography>
                <Typography color="inherit" variant="body2" align="justify">
                  King Hrafn is ill, wasting away before the people of his kingdom. His three children are triplets, 
                  leaving no clear successor to the throne. Each child is secretly gifted with an affinity for a particular 
                  animal that helps them maintain the peace within their homeland, but none of them know why or how best to use it. 
                  Follow Asbjorn, Audolf, and Ambjorg as they strive to find their own path and maintain the peace their 
                  parents built for their kingdom--all while defending it from threats from within.
                </Typography>
                <Button
                color="primary"
                size="large"
                variant="contained"
                href="https://a.co/d/hlyfkxP"
                align="center"
                sx={{ 
                    mt: 2,
                }}
                >
                Order on Amazon
          <br/> or Read on Kindle Unlimited
                </Button>
            </Grid>
        </Grid>
    </ProductHeroLayoutBooks>
  );
}
