import * as React from "react";
import Typography from "../components/Typography";
import { Box } from "@mui/material";
import ProductHeroLayoutAbout from "./ProductHeroLayoutAbout";
import Button from "../components/Button";

const backgroundImage =
  "/about_me_background.png";

export default function ProductHeroAbout() {
  
  return (
    <ProductHeroLayoutAbout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "center",
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Box
          component="img"
          src="/author_profile_photo.jpg"
          height="auto"
          width="20%"
          display="flex"
          sx={{ 
            justifyContent: "center",
            borderRadius: "50%",
          }}
        />
      <Typography color="inherit" align="center" variant="h3" id="about" sx={{
                display: "flex",
                justifyContent: "center",
                my: "20px"}}>
        About the Author
      </Typography>
      <Typography color="inherit" align="center" variant="body2" sx={{
                justifyContent: "left",
                my: "5px"}}>
        A software architect by day, Emily enjoys reading, writing, knitting, crocheting, 
        sewing, running, and learning martial arts with her family in her spare time. She is 
        supported by her longtime husband and two wonderful children, who endure her eccentricities with enthusiasm.
        </Typography>
        <Typography color="inherit" align="center" variant="body2" sx={{
                justifyContent: "left",
                my: "5px"}}>Her published works include Involuntarily Immortal, a contemporary fantasy novel about loss and redemption;
                The Raven's Children, a standalone fantasy novel set in an alt-Norse world; and
                Sunchaser, an introductory fantasy novella set in a world to which the author will return in late 2024 or early 2025.
        </Typography>
        <Button
          color="primary"
          size="large"
          variant="contained"
          href="https://www.amazon.com/stores/Emily-Barlow/author/B0BQJQ1L96"
          sx={{ 
            mt: 2,
          }}
        >
          Amazon author page
        </Button>
        <Button
          color="primary"
          size="large"
          variant="contained"
          href="https://circleoforigin.blog/indie-reads/"
          sx={{ 
            mt: 2,
          }}
        >
          Circle of Origin blog
        </Button>
    </ProductHeroLayoutAbout>
  );
}
