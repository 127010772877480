import * as React from "react";
import { useState } from "react";
import { Dialog } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import Button from "../components/Button";

const backgroundImage =
  "/website_background_involuntarily_immortal.png";

export default function ProductHero() {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (!open)
      setOpen(true);
    else
      setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    
      <ProductHeroLayout
        sxBackground={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
        }}
      >
        {/* Increase the network loading priority of the background image. */}
        <img
          style={{ display: "none" }}
          src={backgroundImage}
          alt="increase priority"
        />
        <Dialog
          open={open}
          onClose={handleClose}
          fullScreen={true}
        >
            <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <iframe title="Sign up" width="100%" height="100%" src="https://82190a13.sibforms.com/serve/MUIFAFtzruMKVT_j3vsVio4ZDl3Mh9Tmq1Pz8cG6gRQVoNIIT-G5W4NIFn0HuEUZVpVp-FQ4Ur57Y7hFZTXdYcBQm2x23dJEJwB4X0aV6szSfjIGVHAy4ym69y3cJPfm-da0xAyOfVkXNB3-RIAnHRVqJGJ69tR0udBUvp8Z4ZfjEoJbIUBS0xQCXXpkUR-l_efx0LB8TPLDpdjx">
          </iframe>
        </Dialog>
        <Typography color="inherit" align="center" variant="h2" marked="center">
          Emily Barlow
        </Typography>
        <Typography
          color="inherit"
          align="center"
          variant="h5"
          sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
        >
          Author of fantasy and speculative fiction
        </Typography>
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={handleOpen}
          sx={{ 
            mt: 2,
          }}
        >
          sign up for my newsletter
          
        </Button>
        <Typography
          color="inherit"
          align="center"
          variant="subtitle1"
          sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
        >
          Get a free short story just for signing up! Stay up to date with new releases and projects and get access 
          to exclusive content just for subscribers.
          
        </Typography>
      </ProductHeroLayout>
  );
}
