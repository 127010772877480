import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "../components/AppBar";
import Toolbar from "../components/Toolbar";
import FacebookIcon from '@mui/icons-material/Facebook';
import { Instagram } from '@mui/icons-material';

const rightLink = {
  fontSize: 16,
  color: "common.white",
  ml: 3,
};

function AppAppBar() {
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <Link
              color="inherit"
              variant="h6"
              underline="none"
              href="#about"
              sx={rightLink}
            >
              {"About"}
            </Link>
            <Link
              color="inherit"
              variant="h6"
              underline="none"
              href="#books"
              sx={rightLink}
            >
              {"Books"}
            </Link>
            <Link
              color="inherit"
              align="center"
              display="block"
              variant="body1"
              href="https://www.facebook.com/profile.php?id=100089226551514"
              key="Facebook"
              sx={rightLink}
            >
                <FacebookIcon />
                <span>facebook</span>
              </Link>
              <Link
                  color="inherit"
                  align="center"
                  display="block"
                  variant="body1"
                  href="https://www.instagram.com/emilybarlowwritesthings/"
                  key="Instagram"
                  sx={rightLink}
                >
                  <Instagram />
                  <span>instagram</span>
                </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
